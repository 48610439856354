// CSS Entrypoint
import './scss/regalia.scss';

// FontAwesome
import './js/FontAwesome';

import { ClassLoader } from './js/utils/ClassLoader';
import { CreditCardForm } from './js/CreditCardForm';
import { CreditCardFormNMI } from './js/CreditCardFormNMI';
import { ProvinceState } from './js/ProvinceState';
import { NavbarHandler } from './js/NavbarHandler';
import { HowBox } from './js/HowBox';
import { Modal } from './js/Modal';
import { PromoCode } from './js/PromoCode';
import { MailPopup } from './js/MailPopup';

const run = async () => {

    ClassLoader.run([
        {
            target: 'CreditCardForm',
            module: CreditCardForm
        },
        {
            target: 'CreditCardFormNMI',
            module: CreditCardFormNMI
        },
        {
            target: 'ProvinceState',
            module: ProvinceState
        },
        {
            target: 'HowBox',
            module: HowBox
        },
        {
            target: 'Modal',
            module: Modal
        },
        {
            target: 'NavbarHandler',
            module: NavbarHandler
        },
        {
            target: 'PromoCode',
            module: PromoCode
        },
        {
            target: 'MailPopup',
            module: MailPopup
        }
    ]);

    if (window.fail) {
        document.getElementById('modal-fail').classList.add('is-active');
    }

    if (window.invalid) {
        document.getElementById('modal-invalid').classList.add('is-active');
    }

    if (window.success) {
        document.getElementById('modal-confirmation').classList.add('is-active');
    }

};

/* Call the run script on load */
(async () => {
    document.readyState != 'loading' ? await run() : document.addEventListener('DOMContentLoaded', run);
})();
